@import url(../node_modules/semantic-ui-css/semantic.min.css);

body {
  background: url("./images/bg.jpg");
  background-size: cover;
  font-family: "Roboto", sans-serif !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", sans-serif !important;
}

h1 {
  border: none !important;
  background: none !important;
}

.ui.basic.active.button,
.ui.basic.buttons .active.button,
.ui.menu {
  background: none !important;
}

.ui.huge.message {
  border-radius: 8px;
}
.ui.menu {
  border: none;
  box-shadow: none;
}
.ui.massive.vertical.menu {
  box-shadow: none;
}

.ui.segment,
.ui.segments .segment {
  background: #ffffff26;
  backdrop-filter: blur(10px);
  border: none;
  box-shadow: 1px 4px 15px 12px #8a8a8a17;
}

.auth-page {
  padding-top: 8%;
}

.header-custom {
  background: #ffffff26 !important;
  backdrop-filter: blur(10px);
  border-radius: 0;
}

.img-question {
  width: 50%;
  display: block;
  margin-bottom: 16px;
  border-radius: 8;
}

.flex {
  display: flex;
}

@media screen and (max-width: 768px) {
  .count-wrapper {
    width: 100%;
  }
  .w-mobile-100 {
    width: 100%;
  }

  .img-question {
    width: 100%;
  }
}

.url-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* grid-template-columns: 1fr 1fr; */
}

.url-box > div {
  display: flex;
  align-items: center;
  gap: 12px;
}

.url-box h3 {
  flex-grow: 1;
  margin: 0 !important;
  height: 36.5px !important;
  padding: 7px 10px !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  border: none !important;
  background: #e7e7e7bf !important;
}

.custom-col {
  cursor: pointer;
}

.custom-col:hover {
  color: #717171;
}
